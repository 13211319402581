import React from 'react';
import { FormattedMessage } from 'react-intl';

import withLayout from '../layout';
import Link from '../components/Link';
import { Stickyroll } from '@stickyroll/stickyroll';

const headlines = [
  'Hello World!',
  'Hello React!',
  'Hello Stickyroll!',
  "Let's continue with the next lesson!",
];
const SecondPage = () => (
  <Stickyroll pages={headlines}>
    {({ page, pageIndex, pages, progress }) => {
      return (
        <div>
          <strong>{page}</strong> of <strong>{pages}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          Progress: <strong>{progress * 100}</strong>
          <h1>{headlines[pageIndex]}</h1>
        </div>
      );
    }}
  </Stickyroll>
);

const customProps = {
  localeKey: 'page2',
};

export default withLayout(customProps)(SecondPage);
